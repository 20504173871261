import React, { useEffect, useRef, useState } from "react";
import "../css/layout.css";
import css from "../Modulecss/Home.module.css";
import axios from "axios";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
//import Rightcontainer from "../Components/Rightcontainer";

import BetCard from "./BetCard";
import RunningCard from "./RunningCard";
import Header from "../Components/Header";
//import { Alert } from "@mui/material";

export default function Homepage({ walletUpdate }) {
  const history = useHistory();
  function urlMatches(game_type) {
    const validPaths = ["classicpro", "ludoking"];
    return validPaths.some((path) => path === game_type);
  }
  useEffect(() => {
    if (!urlMatches(game_type)) {
      history.push("/");
    }
  });

  let userID = useRef();
  const isMounted = useRef(true);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const wssurl = process.env.REACT_APP_SOCKETURL;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  /// user details start

  const [user, setUser] = useState();
  const [created, setCreated] = useState([]);
  const [socket, setSocket] = useState();

  const [userAllData, setUserAllData] = useState();

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data._id);
        setUserAllData(res.data);
        userID.current = res.data._id;
        setMount(true);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  /// user details end

  const [game_type, setGame_type] = useState("ludoking");
  const [Game_Ammount, setGame_Ammount] = useState(0);

  const ChallengeCreate = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    socket.emit("gameCreated", {
      Game_Ammount,
      Game_type: game_type,
      user_id: userID.current,
    });
  };

  const [allgame, setallgame] = useState([]);
  const [mount, setMount] = useState(false);
  //const [ALL, setALL] = useState();
  const [dummy, setDummy] = useState();
  const [runningGames, setRunningGames] = useState();
  const [ownRunning, setOwnRunning] = useState([]);
  const Allgames = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
  };
    const [sitesetting, setsitesetting] = useState()

  const roledata = async () => {
    // const access_token = localStorage.getItem("token");
    await axios.get(baseUrl+`settings/data`).then((res) => {
    //   setUser(res.data._id);
      setsitesetting(res.data);
    //   userID.current=res.data._id;
    //   setMount(true);
    })
    .catch(e=>{
      if (e.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('token');
        window.location.reload()
        setTimeout(() => {
          //  history.push("/login")
        }, 500);
      }
      if (e.response.status === 400 || e.response.status === 429) {
        Swal.fire({
          title: 'Please refresh!',
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    })
  };

  function winnAmount(gameAmount) {
    let profit = null;
    if (gameAmount >= 50 && gameAmount <= 250)
      profit = gameAmount * sitesetting.commission / 100;
    else if (gameAmount > 250 && gameAmount <= 500)
    profit = gameAmount * sitesetting.commission / 100;
    else if (gameAmount > 500)
      profit = gameAmount * sitesetting.commission / 100;
    return gameAmount - profit;
  }

  useEffect(() => {
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };
    const access_token = localStorage.getItem("token");

    let socket = new WebSocket(wssurl + "/server?t=" + access_token);
    //let socket = new WebSocket("ws://192.168.29.119:5001/server");
    function openFunc() {
      socket.onopen = () => {
        setTimeout(() => {
          socket.emit("challengeOngoing");
          socket.emit("Ongoing");
          //          socket.emit("gameCreated", { user_id: userID.current });
        }, 500);
        console.log("websocket is connected 👍");

        setSocket(socket);
        socket.pingTimeout = setTimeout(() => {
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      };
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          if (socket._socketListeners[event]) {
            socket._socketListeners[event](data);
          }
        } catch (error) {
          console.log(error);
        }
      };

      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          console.log("ping terminate works 🏩");
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      });
      socket.listen("recieveGame", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        if (data.error == 200) {
          const games = data.game;
          if (games.Created_by)
            if (
              games.Created_by == userID.current &&
              (games.Status === "new" || games.Status === "requested")
            ) {
              owenedCreated.push(games);
            } else {
              remainingGame.push(games);
            }
        } else if (data.error == 400 && data.userid === userID.current) {
          Swal.fire({
            title: data.msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });

      socket.listen("updateRunning", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        walletUpdate();
      });

      socket.listen("acceptor_seen", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.Created_by && ele.Accepetd_By)
            if (
              ele.Created_by._id == userID.current ||
              ele.Accepetd_By._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("resultUpdateReq", (data) => {
        let owenedRunning = [],
          remainingRunning = [];
        data.forEach(function (ele) {
          if (ele.Created_by && ele.Accepetd_By)
            if (
              ele.Created_by._id == userID.current ||
              ele.Accepetd_By._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
        walletUpdate();
      });

      socket.listen("startAcepptor", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        walletUpdate();
      });

      socket.listen("challengeAccepted", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("updateReject", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });

      socket.listen("ongoingChallenge", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.openBattle.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
        let owenedRunning = [],
          remainingRunning = [];
        data.runningBattle.forEach(function (ele) {
          if (ele.Created_by && ele.Accepetd_By)
            if (
              ele.Created_by._id == userID.current ||
              ele.Accepetd_By._id == userID.current
            ) {
              owenedRunning.push(ele);
            } else {
              remainingRunning.push(ele);
            }
        });
        setOwnRunning(owenedRunning);
        setRunningGames(remainingRunning);
      });
      socket.listen("onChallenge", (data) => {
        let owenedCreated = [];
        data.forEach(function (ele) {
//          if (game_type == ele.Game_type) {
            owenedCreated.push(ele);
  //        }
        });

        setDummy(owenedCreated);
      });

      socket.listen("updateDelete", (data) => {
        let owenedCreated = [],
          remainingGame = [];
        data.forEach(function (ele) {
          if (ele.Created_by)
            if (
              ele.Created_by._id == userID.current &&
              (ele.Status == "new" || ele.Status == "requested")
            ) {
              owenedCreated.push(ele);
            } else {
              remainingGame.push(ele);
            }
        });
        setCreated(owenedCreated);
        setallgame(remainingGame);
      });
    }
    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");
        if (isMounted.current) {
          const access_token = localStorage.getItem("token");

          clearTimeout(socket.pingTimeout);
          setSocket(undefined);
          socket = new WebSocket(wssurl + "/server?t=" + access_token);
          openFunc();
          listenFunc();
          closeFunc();
        }
      };
    }
    openFunc();
    listenFunc();
    closeFunc();

    return () => {
      isMounted.current = false;
      clearTimeout(socket.pingTimeout);
      setSocket(undefined);
      socket.close();
    };
  }, []);

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      setTimeout(() => {
        //  history.push("/login")
      }, 500);
    }
    role();
    roledata();
    if (mount) {
    }
  }, [mount]);

  const AcceptChallang = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .put(
        baseUrl + `challange/accept/${id}`,
        {
          Accepetd_By: headers,
          Acceptor_by_Creator_at: Date.now(),
        },
        {
          headers,
        }
      )
      .then((res) => {
        if (res.data.msg === "you have already enrolled") {
          Swal.fire({
            title: "You have already enrolled",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        if (res.data.msg === "Insufficient balance") {
          Swal.fire({
            title: "Insufficient balance",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Allgames(res.data);
          socket.emit("acceptGame");
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  //reject Game
  const RejectGame = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    socket.emit("gameRejected", { user_id: userID.current, gameid: id });
  };

  //delete
  const deleteChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    socket.emit("deleteGame", _id);
  };

  ///challange/running/update/

  const updateChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/running/update/${_id}`,
        {
          Acceptor_seen: true,
        },
        { headers }
      )
      .then((res) => {
        socket.emit("game_seen");
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        console.log(e);
      });
  };

  const handleRadioChange = (event) => {
    setGame_type(event.target.value);
  };

  const handleImageClick = (value) => {
    setGame_type(value);
  };
  // const [roomCode, setRoomCode] = useState()

  const getPost = async (Id) => {
    socket.emit("roomCode", { game_id: Id, status: "running" });
  };

  return (
    <>
      {/* // //backgroundImage: "url('bg.jpg')" */}
      <Header user={userAllData} />
      <div className="leftContainer" style={{ minHeight: "100vh" }}>
        {(game_type === "Ludo Instant" && (
          <div style={{ marginTop: "80px" }}>
            <div
              style={{
                backgroundColor: "#aaa",
                height: "60px",
                marginTop: "5px",
                width: "100%",
                display: "flex",
              }}
            >
              <p style={{ width: "20%" }}>Play 5</p>
              <button
                onClick={() => {
                  ChallengeCreate(50);
                }}
                style={{ width: "20%" }}
              >
                Rs 50
              </button>
            </div>
          </div>
        )) || (
          <div
            className={css.mainArea}
            style={{ paddingTop: "60px", minHeight: "100vh" }}
          >
            <span className={`${css.cxy} ${css.battleInputHeader} mt-2`}>
              Create a Battle! (50-50k)
            </span>

            <div className="mx-auto d-flex my-1 w-50">
              <div>
                <input
                  style={{ textShadow: "0" }}
                  className={`loginbtn11`}
                  type="tel"
                  maxLength={6}
                  placeholder="Amount"
                  onChange={(e) => setGame_Ammount(e.target.value)}
                />
              </div>

              <div className="set ml-1 ">
                <button
                  className={`btngrd btngrd-1 cxy  position-static `}
                  style={{
                    margin: "20px !important",
                    padding: "16px",
                    height: "10px",
                    borderColor: "transparent",
                    borderRadius: "0",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    ChallengeCreate();
                  }}
                >
                  Set
                </button>
              </div>
            </div>
            
            <div className="mx-auto d-flex my-2 w-50">
            <center>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div
                    onClick={() => handleImageClick("ludoking")}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/Images/ludoking.png`}
                      alt=""
                      width="80px"
                    />
                    <input
                      type="radio"
                      id="ludoking"
                      name="ludo"
                      value="ludoking"
                      checked={game_type === "ludoking"}
                      onChange={handleRadioChange}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                  <div
                    onClick={() => handleImageClick("classicpro")}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/Images/ludozo.png`}
                      alt=""
                      width="80px"
                    />
                    <input
                      type="radio"
                      id="ludozo"
                      name="ludo"
                      value="classicpro"
                      checked={game_type === "classicpro"}
                      onChange={handleRadioChange}
                      style={{ marginLeft: "10px" }}
                    />
                  </div>
                </div>
              </center> 
            </div>
            
            <div className={css.dividerX}></div>

            <div className="px-4 py-3">
              <div className="mb-3">
                <img
                  src={
                    process.env.PUBLIC_URL + "/Images/Homepage/battleIcon.png"
                  }
                  alt=""
                  width="20px"
                />
                <span
                  style={{ color: "#fff" }}
                  className={`ml-2 ${css.gamesSectionTitle}`}
                >
                  Open Battles
                </span>
                <span
                  className={`${css.gamesSectionHeadline} text-uppercase position-absolute mt-2 font-weight-bold`}
                  style={{ right: "1.5rem", color: "#fff" }}
                >
                  Rules
                  <NavLink to="/Rules">
                    <img
                      className="ml-2"
                      src={process.env.PUBLIC_URL + "/Images/Homepage/info.png"}
                      alt=""
                    />
                  </NavLink>
                </span>
              </div>
              {created &&
                created.map(
                  (allgame) =>
                    allgame._id && (
                      <BetCard
                        key={allgame._id}
                        allgame={allgame}
                        user={user}
                        deleteChallenge={deleteChallenge}
                        getPost={getPost}
                        RejectGame={RejectGame}
                        winnAmount={winnAmount}
                        AcceptChallang={AcceptChallang}
                        updateChallenge={updateChallenge}
                      />
                    )
                )}
              {allgame &&
                allgame.map(
                  (allgame) =>
                    (allgame.Status == "new" ||
                      (allgame.Status == "requested" &&
                        (user == allgame.Created_by._id ||
                          (allgame.Accepetd_By != null &&
                            user == allgame.Accepetd_By._id))) ||
                      (allgame.Status == "running" &&
                        allgame.Accepetd_By != null &&
                        user == allgame.Accepetd_By._id &&
                        allgame.Acceptor_seen == false)) &&
                    allgame._id && (
                      <BetCard
                        key={allgame._id}
                        allgame={allgame}
                        user={user}
                        deleteChallenge={deleteChallenge}
                        getPost={getPost}
                        RejectGame={RejectGame}
                        winnAmount={winnAmount}
                        AcceptChallang={AcceptChallang}
                        updateChallenge={updateChallenge}
                      />
                    )
                )}
            </div>
            <div className={css.dividerX}></div>
            <div className="px-4 py-3">
              <div className="mb-2">
                <img
                  src={
                    process.env.PUBLIC_URL + "/Images/Homepage/battleIcon.png"
                  }
                  alt=""
                  width="20px"
                />
                <span
                  style={{ color: "#fff" }}
                  className={`ml-2 ${css.gamesSectionTitle}`}
                >
                  Running Battles
                </span>
              </div>
              {ownRunning &&
                ownRunning.map((runnig) => {
                  if (
                    (runnig.Accepetd_By != null &&
                    user == runnig.Accepetd_By._id
                      ? (runnig.Status === "running" &&
                          user == runnig.Accepetd_By._id &&
                          runnig.Acceptor_seen == true) ||
                        runnig.Status === "pending"
                      : (runnig.Status === "running" &&
                          user == runnig.Created_by._id) ||
                        runnig.Status === "pending") ||
                    runnig.Status == "conflict"
                  )
                    return (
                      <RunningCard
                        key={runnig._id}
                        runnig={runnig}
                        user={user}
                        winnAmount={winnAmount}
                      />
                    );
                })}

              {runningGames &&
                runningGames.map((runnig) => {
                  if (
                    (user == runnig.Accepetd_By._id ||
                    user == runnig.Created_by._id
                      ? user == runnig.Accepetd_By._id
                        ? (runnig.Status === "running" &&
                            user == runnig.Accepetd_By._id &&
                            runnig.Acceptor_seen == true) ||
                          (runnig.Status === "pending" &&
                            runnig.Acceptor_status == null)
                        : (runnig.Status === "running" &&
                            user == runnig.Created_by._id) ||
                          (runnig.Status === "pending" &&
                            runnig.Creator_Status == null)
                      : runnig.Status === "running" ||
                        runnig.Status === "pending") 
                  )
                    return (
                      <RunningCard
                        key={runnig._id}
                        runnig={runnig}
                        user={user}
                        winnAmount={winnAmount}
                      />
                    );
                })}
              {dummy &&
                dummy.map((runnig) => {
                  return (
                    <RunningCard
                      key={runnig._id}
                      runnig={runnig}
                      user={user}
                      winnAmount={winnAmount}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>
      {/* <div className="rightContainer">
        <Rightcontainer />
      </div> */}
    </>
  );
}
