import React, { useEffect, useState, useRef } from "react";
import "../css/viewGame1.css";
import "../css/layout.css";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import "../css/Loader.css";

export default function ViewGame1(props) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const history = useHistory();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const location = useLocation();
  const path = location.pathname.split("/")[2];

  const [user, setUser] = useState();

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data._id);
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          history.push("/login");
        }
      });
  };
  function urlMatches(game_type) {
    const validPaths = [
      "classiclite",
      "classicpro",
      "classicextreme",
      "oneToken",
      "twoToken",
      "threeToken",
    ];
    return validPaths.some((path) => path === game_type);
  }
  //  role();

  const playGame = (gameToken, path) => {
    const targetFrame = document.getElementById("iframe");
    const targetOrigin = "https://classicv1.ludozo.com";
    const message = {
      sUserToken: gameToken,
      sGameId: path,
      sRootUrl: "https://classicv1api.ludozo.com",
    };
    setTimeout(() => {
      targetFrame.contentWindow.postMessage(message, targetOrigin);
    }, 200);
  };
  const getGame = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `challange/${path}`, { headers })
      .then((res) => {
        if (
          res.data.Status == "new" ||
          res.data.Status == "requested" ||
          res.data.Status == "drop" ||
          res.data.Status == "completed"
        ) {
          history.push("/");
        } else {
          let gt = res.data.Game_type;
          if (urlMatches(gt)) {
            if (res.data.Created_by && res.data.Accepetd_By) {
              if (user == res.data.Created_by._id) {
                if (
                  res.data.Created_By_web_game == "" ||
                  res.data.Created_By_web_game == null
                ) {
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                } else {
                  playGame(res.data.Created_By_web_game, res.data._id);
                }
              } else if (user == res.data.Accepetd_By._id) {
                if (
                  res.data.Accepetd_By_web_game == "" ||
                  res.data.Accepetd_By_web_game == null
                ) {
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                } else {
                  playGame(res.data.Accepetd_By_web_game, res.data._id);
                }
              }
            }
          } else {
          }
        }
      })
      .catch((e) => {
        history.push("/");
        console.log(e);
      });
  };
  useEffect(() => {
    role();
    getGame();
  }, [user]);
  return (
    <>
      <iframe
        id="iframe"
        width={dimensions.width > 500 ? 500 : dimensions.width}
        height={dimensions.height > 700 ? 700 : dimensions.height}
        src="https://classicv1.ludozo.com"
      ></iframe>
    </>
  );
}
