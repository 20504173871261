// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  background: linear-gradient(
    #000428,
    #000428,
    #004e92
  ); /* Example gradient from dark blue to lighter blue */
  border-top: 1px solid aqua;
  position: fixed;
  bottom: 0;
  max-width: 480px;
  width: 100%;
}

.footer-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 18px;
}
.homel {
  font-size: 22px;
  font-weight: bolder;
}
`, "",{"version":3,"sources":["webpack://./src/app2.js/Components/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,eAAe;EACf;;;;GAIC,EAAE,oDAAoD;EACvD,0BAA0B;EAC1B,eAAe;EACf,SAAS;EACT,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".footer2 {\r\n  display: flex;\r\n  justify-content: space-around;\r\n  align-items: center;\r\n  padding: 10px 0;\r\n  background: linear-gradient(\r\n    #000428,\r\n    #000428,\r\n    #004e92\r\n  ); /* Example gradient from dark blue to lighter blue */\r\n  border-top: 1px solid aqua;\r\n  position: fixed;\r\n  bottom: 0;\r\n  max-width: 480px;\r\n  width: 100%;\r\n}\r\n\r\n.footer-link {\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  text-decoration: none;\r\n  color: white;\r\n  font-size: 18px;\r\n}\r\n.homel {\r\n  font-size: 22px;\r\n  font-weight: bolder;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
